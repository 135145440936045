@import 'variables';

$prefix: $antPrefix + -drawer;

.#{$prefix}-header {
    border: none;

    .#{$prefix}-title {
        font-size: 24px;
        font-weight: 600;
    }
}

.#{$prefix}-body {
    padding: 10px 20px 20px 20px;

    .#{$antPrefix}-form-horizontal {
        .#{$antPrefix}-row {
            flex-wrap: nowrap;

            > .#{$antPrefix}-col:first-child {
                max-width: 66%;
                min-width: 205px;
            }
            > .#{$antPrefix}-col:nth-child(2) {
                min-width: 160px;
            }
        }
    }

    h3 {
        margin-bottom: 24px;
    }
}

.new-skill-drawer .#{$prefix}-body {
    .#{$antPrefix}-form-horizontal {
        margin-top: 32px;

        > .#{$antPrefix}-form-item {
           > .#{$antPrefix}-form-item-label {
               min-width: 90px;
               max-width: 90px;
           } 
           > .#{$antPrefix}-form-item-control {
               width: 80%;
               max-width: 400px;
           }
        }
    }
}

.new-deal-drawer {
    .#{$antPrefix}-form-item {
        flex-flow: row wrap !important;
    }
}

.new-company-drawer {
    .#{$prefix}-body {
        overflow-y: auto;
        overflow-x: hidden;

        .company-contacts-wrapper {
            position: relative;
            .anticon-close {
                position: absolute;
                top: 16px;
                right: 16px;
            }
            > .#{$antPrefix}-form-item > .#{$antPrefix}-form-item-control {
                min-width: 350px;
            }
            > .company-contact-checkbox {
                flex-flow: row nowrap;
                margin-top: 20px;
                > .#{$antPrefix}-form-item-label {
                    padding: 0;
                    display: flex;
                    align-items: center;
                }
            }
        }

        .company-contact-button {
            overflow: hidden;
            > .#{$antPrefix}-form-item-control {
                min-width: 100% !important;
                margin-left: 0;
            }
        }
    }
}

.new-trainee-drawer {
    .#{$prefix}-body {
        .#{$antPrefix}-form-horizontal {
            .#{$antPrefix}-row {
    
                > .#{$antPrefix}-col:first-child {
                    min-width: 190px;
                }
                > .#{$antPrefix}-col:nth-child(2) {
                    min-width: 300px;
                }
            }
        }
    }
}