@import 'variables';

$prefix: $antPrefix + -menu;
$darkClass: $prefix + -dark;

$activeArrowClass: '.#{$prefix}-item:hover > .#{$prefix}-submenu-title > .#{$prefix}-submenu-arrow, .#{$prefix}-item-active > .#{$prefix}-submenu-title > .#{$prefix}-submenu-arrow, .#{$prefix}-submenu-active > .#{$prefix}-submenu-title > .#{$prefix}-submenu-arrow, .#{$prefix}-submenu-open > .#{$prefix}-submenu-title > .#{$prefix}-submenu-arrow, .#{$prefix}-submenu-selected > .#{$prefix}-submenu-title > .#{$prefix}-submenu-arrow, .#{$prefix}-submenu-title:hover > .#{$prefix}-submenu-title > .#{$prefix}-submenu-arrow, .#{$prefix}-item:hover > .#{$prefix}-submenu-title:hover > .#{$prefix}-submenu-arrow, .#{$prefix}-item-active > .#{$prefix}-submenu-title:hover > .#{$prefix}-submenu-arrow, .#{$prefix}-submenu-active > .#{$prefix}-submenu-title:hover > .#{$prefix}-submenu-arrow, .#{$prefix}-submenu-open > .#{$prefix}-submenu-title:hover > .#{$prefix}-submenu-arrow, .#{$prefix}-submenu-selected > .#{$prefix}-submenu-title:hover > .#{$prefix}-submenu-arrow, .#{$prefix}-submenu-title:hover > .#{$prefix}-submenu-title:hover > .#{$prefix}-submenu-arrow';
$activeLinkClass: '.#{$prefix}-item:hover,.#{$prefix}-item-active,.#{$prefix}-submenu-active,.#{$prefix}-submenu-open,.#{$prefix}-submenu-selected,.#{$prefix}-submenu-title:hover';
$menuBorderRight: '.#{$prefix}-vertical .#{$prefix}-item::after, .#{$prefix}-vertical-left .#{$prefix}-item::after, .#{$prefix}-vertical-right .#{$prefix}-item::after, .#{$prefix}-inline .#{$prefix}-item::after';

@mixin selectedItemStyle($prefixClass) {
  &.#{$prefixClass}:not(.#{$prefix}-horizontal) .#{$prefix}-item-selected {
    color: $primaryColor;
    background-color: $itemHoverBackground;
  }

  &.#{$prefixClass} .#{$prefix}-item-selected {

    &>a,
    &>a:hover {
      color: $primaryColor;
    }
  }
}

%baseMenu {
  #{$activeLinkClass} {
    &>a {
      color: $primaryColor;
    }
  }

  color: $textColor;
  background: $componentBackground;
}

%lightMenu {
  @extend %baseMenu;

  .#{$prefix}-item:hover,
  .#{$prefix}-item,
  .#{$prefix}-item-active,
  .#{$prefix}:not(.#{$prefix}-inline) .#{$prefix}-submenu-open,
  .#{$prefix}-submenu-active,
  .#{$prefix}-submenu-open,
  .#{$prefix}-submenu-title:hover {
    color: $primaryColor;
  }

  .#{$prefix}-item:active,
  .#{$prefix}-submenu-title:active {
    background-color: transparent;
  }
}

.#{$prefix}-sub.#{$prefix}-inline>.#{$prefix}-item {
  color: $textColor;

  &.#{$prefix}-item-active {
    color: $primaryColor;
  }
}

.#{$prefix}-inline,
.#{$prefix}-vertical {
  border-right: transparent;
}

.#{$prefix}-item a {
  color: $textColor;
}

.#{$prefix}-item a:hover {
  color: $secondaryHover;
}

.#{$prefix} {
  @extend %lightMenu;
  @include selectedItemStyle($prefix);
}

.#{$prefix}-submenu>.#{$prefix} {
  color: $textColor;
  background: $componentBackground;
}

#{$menuBorderRight} {
  border-color: $primaryColor;
}

.#{$prefix}-item-selected {
  color: $primaryColor;
}

.#{$prefix}-submenu-inline>.#{$prefix}-submenu-title .#{$prefix}-submenu-arrow {

  &::before,
  &::after {
    background-image: linear-gradient(to right, $textColor, $textColor);
  }
}

.#{$prefix}-submenu-selected>.#{$prefix}-submenu-title .#{$prefix}-submenu-arrow,
.#{$prefix}-submenu-open>.#{$prefix}-submenu-title .#{$prefix}-submenu-arrow,
.#{$prefix}-submenu>.#{$prefix}-submenu-title:hover .#{$prefix}-submenu-arrow,
{

&::before,
&::after {
  background-image: linear-gradient(to right, $primaryColor, $primaryColor);
}
}

.#{$prefix}-vertical>.#{$prefix}-item,
.#{$prefix}-vertical-left>.#{$prefix}-item,
.#{$prefix}-vertical-right>.#{$prefix}-item,
.#{$prefix}-inline>.#{$prefix}-item,
.#{$prefix}-vertical>.#{$prefix}-submenu>.#{$prefix}-submenu-title,
.#{$prefix}-vertical-left>.#{$prefix}-submenu>.#{$prefix}-submenu-title,
.#{$prefix}-vertical-right>.#{$prefix}-submenu>.#{$prefix}-submenu-title,
.#{$prefix}-inline>.#{$prefix}-submenu>.#{$prefix}-submenu-title,
.#{$prefix}-vertical .#{$prefix}-item>a,
.#{$prefix}-vertical-left .#{$prefix}-item>a,
.#{$prefix}-vertical-right .#{$prefix}-item>a,
.#{$prefix}-inline .#{$prefix}-item>a {
  display: flex;
  align-items: center;
  margin: 0;

  >span {
    font-size: 16px;
    font-weight: 400;
  }
}

.#{$prefix}-vertical .#{$prefix}-item:not(:last-child),
.#{$prefix}-vertical-left .#{$prefix}-item:not(:last-child),
.#{$prefix}-vertical-right .#{$prefix}-item:not(:last-child),
.#{$prefix}-inline .#{$prefix}-item:not(:last-child),
.#{$prefix}-vertical .#{$prefix}-submenu,
.#{$prefix}-vertical-left .#{$prefix}-submenu,
.#{$prefix}-vertical-right .#{$prefix}-submenu,
.#{$prefix}-inline .#{$prefix}-submenu {
  margin: 0;
  padding-bottom: 0;
}

.#{$prefix}-vertical .#{$prefix}-item:not(:last-child),
.#{$prefix}-vertical-left .#{$prefix}-item:not(:last-child),
.#{$prefix}-vertical-right .#{$prefix}-item:not(:last-child),
.#{$prefix}-inline .#{$prefix}-item:not(:last-child),
.#{$prefix}-vertical .#{$prefix}-submenu,
.#{$prefix}-vertical-left .#{$prefix}-submenu,
.#{$prefix}-vertical-right .#{$prefix}-submenu,
.#{$prefix}-inline .#{$prefix}-submenu {
  margin: 0 0 16px 0;
}

.#{$prefix}-submenu>.#{$prefix}-submenu-title+.#{$prefix}-sub>.#{$prefix}-item:not(:last-child) {
  margin: 0;
}

.#{$prefix}-submenu>.#{$prefix}-submenu-title+.#{$prefix}-sub>.#{$prefix}-item,
.#{$prefix}-submenu-popup>.#{$prefix}>.#{$prefix}-item {
  >a>span {
    text-transform: none;
    font-size: 15px;
    letter-spacing: 0;
    font-weight: 400;
  }
}

.#{$prefix}-inline-collapsed>.#{$prefix}-item,
.#{$prefix}-inline-collapsed>.#{$prefix}-item-group>.#{$prefix}-item-group-list>.#{$prefix}-item,
.#{$prefix}-inline-collapsed>.#{$prefix}-item-group>.#{$prefix}-item-group-list>.#{$prefix}-submenu>.#{$prefix}-submenu-title,
.#{$prefix}-inline-collapsed>.#{$prefix}-submenu>.#{$prefix}-submenu-title {
  padding: 0;
  display: flex;
  justify-content: center;
}

.#{$prefix}.#{$prefix}:not(.#{$prefix}-horizontal) .#{$prefix}-item-selected,
.#{$prefix}-submenu-selected {
  background-color: $componentBackground;
  color: $primaryColor;
}

.#{$prefix}-vertical .#{$prefix}-submenu-selected,
.#{$prefix}-vertical-left .#{$prefix}-submenu-selected,
.#{$prefix}-vertical-right .#{$prefix}-submenu-selected,
.#{$prefix}-item:hover,
.#{$prefix}-item-active,
.#{$prefix}:not(.#{$prefix}-inline) .#{$prefix}-submenu-open,
.#{$prefix}-submenu-active,
.#{$prefix}-submenu-title:hover {
  color: $primaryColor;
}

.#{$prefix}-submenu-popup>.#{$prefix}>.#{$prefix}-item:not(:last-child) {
  margin-bottom: 0;
}

.#{$prefix}.menu_super_admin {
  background-color: #2D2D2D;
  color: #fff;

  .#{$prefix}-item:hover,
  .#{$prefix}-item-active,
  .#{$prefix}-item-selected,
  .#{$prefix}-submenu-active,
  .#{$prefix}-submenu-open,
  .#{$prefix}-submenu-selected,
  .#{$prefix}-submenu-title:hover {
    background-color: #2D2D2D;
  }

  a {
    color: #fff;
  }

  a.active,
  .#{$prefix}-item a:hover,
  .#{$prefix}-item a:focus,
  .#{$prefix}-submenu-title:hover,
  .#{$prefix}-submenu-open .#{$prefix}-submenu-title,
  .#{$prefix}-submenu-active .#{$prefix}-submenu-title {
    color: #FF6D00;
  }

  .#{$prefix}-submenu-inline>.#{$prefix}-submenu-title .#{$prefix}-submenu-arrow {

    &::before,
    &::after {
      background-image: linear-gradient(to right, #fff, #fff);
    }
  }

  .#{$prefix}-submenu-selected>.#{$prefix}-submenu-title .#{$prefix}-submenu-arrow,
  .#{$prefix}-submenu-open>.#{$prefix}-submenu-title .#{$prefix}-submenu-arrow,
  .#{$prefix}-submenu>.#{$prefix}-submenu-title:hover .#{$prefix}-submenu-arrow {

    &::before,
    &::after {
      background-image: linear-gradient(to right, #FF6D00, #FF6D00);
    }
  }

  .#{$prefix}-submenu>.#{$prefix} {
    background: #525252;

    .#{$prefix}-item a:hover,
    .#{$prefix}-item a:focus {
      color: #FF6D00;
    }

    .#{$prefix}-item-active,
    .#{$prefix}-item:hover,
    .#{$prefix}-item:focus {
      background: #525252;
    }
  }
}

.#{$darkClass} {
  @extend %baseMenu;

  &.#{$prefix} {
    background: $componentBackground;
  }

  .#{$prefix}-inline.#{$prefix}-sub,
  &.#{$prefix}-sub {
    background: transparent;
  }

  .#{$prefix}-item:hover,
  .#{$prefix}-item-active,
  .#{$prefix}-submenu-active,
  .#{$prefix}-submenu-open,
  .#{$prefix}-submenu-selected,
  .#{$prefix}-submenu-title:hover {
    color: $primaryColor;
  }

  @include selectedItemStyle($darkClass);
}