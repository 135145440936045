@import 'variables';

$prefix: $antPrefix + -radio;

.#{$prefix} {
  &-wrapper,
  & {
    color: $textColor;
  }
  &-wrapper:hover .#{$prefix}-inner,
  &:hover .#{$prefix}-inner,
  &-input:focus + .#{$prefix}-inner {
    border-color: $primaryColor;
  }
  &-inner {
    background-color: $bodyBackground;
    border-color: $borderColor;
  }
  &-checked {
    &:after {
      border-color: $primaryColor;
    }
    .#{$prefix}-inner {
      border-color: $primaryColor;
      background-color: $bodyBackground;
      &::after {
        background-color: $primaryColor;
      }
    }
  }
  &-indeterminate .#{$prefix}-inner {
    background-color: $bodyBackground;
    border-color: $borderColor;
    &::after {
      background-color: $primaryColor;
    }
  }
  &-disabled .#{$prefix}-inner {
    background-color: $bodyBackground;
  }
}

.#{$prefix}-button-wrapper:not(.#{$prefix}-button-wrapper-disabled) {
  background: $componentBackground;
  color: $textColor;
}


.#{$prefix}-button-wrapper-checked:not(.#{$prefix}-button-wrapper-disabled) {
  background: $contrastBackground;
  color: $primaryColor;
}

.#{$prefix}-button-wrapper-checked:not(.#{$prefix}-button-wrapper-disabled):hover {
  border-color: $primaryColor;
  color: $primaryColor;
}

.#{$prefix}-button-wrapper-checked:not(.#{$prefix}-button-wrapper-disabled),
.#{$prefix}-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).#{$prefix}-button-wrapper:first-child,
.#{$prefix}-button-wrapper-checked:not(.#{$prefix}-button-wrapper-disabled):first-child {
  border-color: $primaryColor;
  border-right-color: $primaryColor;
}

.#{$prefix}-button-wrapper-checked:not(.#{$prefix}-button-wrapper-disabled)::before,
.#{$prefix}-button-wrapper-checked:not(.#{$prefix}-button-wrapper-disabled):hover::before {
  background-color: $primaryColor;
}

.#{$antPrefix}-form-item .#{$prefix}-group {
  padding-top: 6px;
}