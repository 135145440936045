@import 'variables';

$prefix: $antPrefix + -card;

.#{$prefix} {
  background: $componentBackground;
  color: $textColor;
  box-shadow: 1px 1px 4px rgba(43, 43, 43, 0.15);
  &-head,
  &-meta-title,
  &-meta-description {
    color: $textColor;
  }
  &-head {
    border-bottom-color: $borderColor;
  }
  &-bordered {
    border-color: $borderColor;
  }
}


.#{$prefix}-bordered {
  border-radius: 4px;
}

.indicator-card {
  border-radius: 4px;
  background: $componentBackground;
  box-shadow: 2px 2px 8px rgba(43, 43, 43, 0.15);
  height: 100%;
  overflow: hidden;

  .#{$prefix}-head {
    padding: 0 16px;
    border-bottom: none;

    &-title {
      font-size: 15px;
      position: relative;
      padding-bottom: 10px;

      &::after {
        content: '';
        width: 60px;
        height: 7px;
        background: $primaryColor;
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 99;
      }
    }
  }

  .#{$prefix}-body {
    padding: 16px;
  }
}

.#{$prefix}-body {
  > h4 {
    font-size: 15px;
    font-weight: 700;
  }
}

.indicator-card-contains-table .#{$prefix}-body {
  overflow: auto;
}

.session-email-files-card {
  .#{$antPrefix}-collapse > .#{$antPrefix}-collapse-item > .#{$antPrefix}-collapse-header {
    padding: 0;
  }
  .#{$antPrefix}-collapse-ghost > .#{$antPrefix}-collapse-item > .#{$antPrefix}-collapse-content > .#{$antPrefix}-collapse-content-box {
    padding: 4px 28px 4px 0;
  }

  .#{$antPrefix}-list-item {
    padding: 6px 0;

    &-meta {
      align-items: center;
      padding-right: 8px;
    }

    .#{$antPrefix}-btn-link {
      padding: 2px;
    }
  }
}

.session-module-card {
  border: 1px solid $primaryColor;

  .#{$prefix}-head {
    background: $primaryColor;
    border-bottom-color: $primaryColor;
    border-radius: 4px 4px 0 0;

    &-title {
      color: #fff;
      font-weight: 700;
    }

    .#{$antPrefix}-btn-dangerous.#{$antPrefix}-btn-link {
      color: #fff;
    }
  }
}

.session-quality-quiz-card {
  border-color: $primaryColor;
  border-radius: 4px;

  > .#{$prefix}-head {
    border-radius: 4px 4px 0 0;
    background: $primaryColor;
    border-color: $primaryColor;
    color: #fff;

    > .#{$prefix}-head-wrapper > .#{$prefix}-extra {
      color: #fff;
      font-size: 16px;
    }
  }

  .#{$antPrefix}-list-item > .#{$prefix} {
    > .#{$prefix}-head {
      border-radius: 4px 4px 0 0;
      background: #474747;
      color: #fff;

      > .#{$prefix}-head-wrapper > .#{$prefix}-extra {
        font-size: 16px;
        color: #fff;
      }
    }
    > .#{$prefix}-body {
      .anticon-user {
        margin-right: 10px;
      }

      > .#{$prefix}:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }
}

.session-quality-global-grade-col {
  .#{$prefix}:nth-child(2) {
    margin-top: 16px !important;
  }
}

.agenda-popover-card {
  margin-bottom: 10px;

  .#{$prefix}-body {
    > .#{$antPrefix}-row > .#{$antPrefix}-col > .#{$antPrefix}-row {
      align-items: center;
    }
    > .#{$antPrefix}-row > .#{$antPrefix}-col .#{$antPrefix}-list .#{$antPrefix}-list-item {
      padding-left: 0;

      span {
        &::before {
          content: '-';
          margin-right: 4px;
        }
      }
    }
  }
}

.bpf-card {
  box-shadow: none;
  
  .#{$prefix}-body {
    padding: 0;

    .#{$antPrefix}-descriptions {
      margin: 0 10px 0 20px;

      .#{$antPrefix}-descriptions-item:last-child {
        max-width: 150px;
        width: 150px;
      }

      .#{$antPrefix}-descriptions-item:first-child {
        max-width: 450px;
        width: 450px;
      }
    }

    > .#{$antPrefix}-divider-horizontal:not(:first-child):not(.bpf-sub-divider) {
      margin-top: 40px;
    }

    > .#{$antPrefix}-divider-horizontal span {
      font-weight: 400;
    }

    > .#{$antPrefix}-divider-horizontal:not(.bpf-sub-divider) {
      color: $primaryColor;
    }

    > .#{$antPrefix}-divider-horizontal.bpf-sub-divider {
      color: $primaryColor;
      margin-bottom: 5px;

      span {
        font-size: 17px;
      }
    }

    > .#{$antPrefix}-divider-horizontal.bpf-sub-divider + .#{$antPrefix}-card {
      margin-bottom: 20px;
    }

    .#{$antPrefix}-card {
      border: none;
      box-shadow: none;
    }

    .#{$antPrefix}-divider-vertical {
      height: 36px;
      margin: 0 6px;
    }
  }
}

.card-no-border {
  box-shadow: none;
}