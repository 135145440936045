@import 'variables';

$prefix: $antPrefix + -page-header;

.#{$prefix} {
  color: $textColor;
  background: $componentBackground;

  &-heading-title,
  &-back-button,
  & > .#{$antPrefix}-breadcrumb a:not(:hover),
  & > .#{$antPrefix}-breadcrumb > span:last-child a {
    color: $textColor;
  }
  & > .#{$antPrefix}-breadcrumb > span:last-child a {
    cursor: auto;
  }

  .#{$antPrefix}-breadcrumb-separator {
    color: $textColorSecondary;
  }
}

.#{$prefix} .#{$antPrefix}-breadcrumb {
  display: none;
}

.#{$prefix}.has-breadcrumb {
  padding: 20px 16px 12px 20px;

  .#{$antPrefix}-breadcrumb + .#{$prefix}-heading {
    margin-top: 0;
  }

  .#{$prefix}-heading-title {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.02em;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      background: $primaryColor;
      height: 2px;
      width: 100%;
      left: 0;
      bottom: 0;
    }
  }
}

.#{$antPrefix}-layout-content > .#{$antPrefix}-layout-content > .#{$prefix}.has-breadcrumb {
  padding: 0 24px 12px 0;
}

.#{$prefix}-back:hover, .#{$prefix}-back-button:hover, .#{$prefix}-back-button .anticon:hover, .#{$prefix}-back-button svg:hover {
  color: $primaryColor;
}