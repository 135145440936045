@import 'variables';

$prefix: $antPrefix + -tag;

.#{$prefix} {
  background: transparent;
  border-color: $borderColorA06;
  a,
  a:hover {
    color: $textColorSecondary;
  }
}

.#{$prefix}-success {
  color: #01B775;
}

.#{$prefix}-orange {
  color: $primaryColor;
}
