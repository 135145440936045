@import 'variables';

$prefix: $antPrefix + -tree;

.#{$prefix} {
  background: $componentBackground;
  color: $textColor;

  .#{$prefix}-checkbox-inner {
    border-radius: 4px;

    &:hover {
      border-color: $primaryColor;
    }
  }
}

.#{$prefix}-checkbox-checked {

  &::after {
    border: none;
  }

  .#{$prefix}-checkbox-inner {
    background-color: $primaryColor;
    border-color: $primaryColor;
  
    &:hover {
      border-color: $primaryColor;
    }
  }
}

.#{$prefix}-checkbox-indeterminate .#{$prefix}-checkbox-inner::after {
  background-color: $primaryColor;
  border-radius: 2px;
}