@import 'variables';

$prefix: $antPrefix + -modal;
$drawerPrefix: $antPrefix + -drawer;
$modalTypes: '&-confirm, &-info, &-success, &-error, &-warning';

.#{$prefix},
.#{$drawerPrefix} {
  color: $textColor;

  &-wrap.no-padding,
  &.no-padding {
    .#{$prefix} {
      &-body {
        padding: 0 !important;
      }
      &-content {
        overflow: hidden;
      }
    }
  }

  &-content {
    background-color: $componentBackground;
  }
  &-close {
    color: $textColorSecondary;
    &:hover {
      color: $textColor;
    }
  }
  &-title {
    color: $textColor;
  }
  &-header,
  &-footer {
    color: $textColor;
    background: transparent;
    border-color: $borderColor;
  }

  #{$modalTypes} {
    &-body .#{$prefix} {
      #{$modalTypes} {
        &-title,
        &-content {
          color: $textColor;
        }
      }
    }
  }
  &-confirm-info .#{$prefix}-confirm-body > .anticon {
    color: $primaryColor;
  }
}

.#{$prefix}-content {
  border-radius: 4px;
}

.session-confirmation-modal {

  margin-top: 12%;

  .#{$prefix}-content {
    border-radius: 4px;
  }

  .#{$prefix}-body {
    padding: 0;
    border-radius: 4px;

    .#{$antPrefix}-alert {
      padding: 28px;
      border: none;
      border-radius: 4px;
      background: #fff;

      &-message {
        margin-bottom: 8px;
        font-weight: 700;
        font-size: 18px;
      }

      &-description {
        font-size: 16px;
      }
    }
  }
}

.session-planning-modal {

  @media (max-width: 850px) {
    width: 100% !important;
  }

  .#{$prefix}-title {
    .#{$antPrefix}-picker {
      margin: 0 10px;
    }
  }

  .#{$prefix}-body {
    > div {
      padding-bottom: 16px;
    }

    .#{$antPrefix}-card-head-title {
      .#{$antPrefix}-tag {
        margin-left: 8px;
      }
    }

    .#{$antPrefix}-card-extra {
      @media (max-width: 1100px) {
        .#{$antPrefix}-btn-dangerous.#{$antPrefix}-btn-link {
          padding: 0;

          span:first-child {
            display: none;
          }
        }
      }
    }
  }
}

.#{$prefix}-body .DraftEditor-editorContainer {
  max-height: 400px;
  overflow: auto;
}
