@import 'variables';

$prefix: $antPrefix + -statistic;

.#{$prefix} {
    line-height: 1;

    &-title {
        margin-bottom: 0;
        color: $textColor;
        font-weight: 400;
    }

    &-content {
        font-family: 'myriad-pro';
        font-weight: 400;
        font-size: 18px;
    }
}

.indicator-card .#{$prefix} {
    line-height: 1.4;
    margin-bottom: 16px;

    &-title {
        margin-bottom: 0;
    }

    &-content {
        font-size: 24px;
        font-weight: 700;
    }
}

.session-invoice-statistic {
    .#{$prefix}-title {
        margin-bottom: 6px;
        font-size: 16px;
    }
    .#{$prefix}-content {
        font-size: 24px;
        font-weight: 700;

        &-value-decimal {
            font-size: 20px;
        }
    }
}