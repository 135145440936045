@import 'variables';

$prefix: $antPrefix + -steps;

.#{$prefix} {
  color: $textColor;
  .#{$prefix}-item-title {
    color: $textColor;
    &::after {
      background: $contrastBackground;
    }
  }

  &-item-process {
    .#{$prefix}-item-icon {
      background-color: $contrastBackground;
      > .#{$prefix}-icon {
        color: $primaryColor;
        > .#{$prefix}-icon-dot {
          background: $primaryColor;
        }
      }
    }
    & > .#{$prefix}-item-container {
      & > .#{$prefix}-item-content {
        .#{$prefix}-item-title {
          color: $primaryColor;
          &::after {
            background-color: $contrastBackground;
          }
        }
        .#{$prefix}-item-subtitle {
          color: $textColor;
        }
      }
      & > .#{$prefix}-item-tail::after {
        background-color: $contrastBackground;
      }
    }
  }

  &-item-wait {
    .#{$prefix}-item-icon {
      background-color: $contrastBackground;
      border-color: $disabledColor;
      & > .#{$prefix}-icon {
        color: $disabledColor;
      }
    }
    & > .#{$prefix}-item-container > .#{$prefix}-item-content {
      & > .#{$prefix}-item-title {
        color: $disabledColor;
        &::after {
          background-color: $contrastBackground;
        }
      }
      .#{$prefix}-item-subtitle {
        color: $textColor;
      }
    }
    & > .#{$prefix}-item-container .#{$prefix}-item-tail::after {
      background-color: $contrastBackground;
    }
  }
  &-item-finish {
    .#{$prefix}-item-icon {
      background-color: $contrastBackground;
      > .#{$prefix}-icon {
        color: $primaryColor;
        > .#{$prefix}-icon-dot {
          background: $primaryColor;
        }
      }
    }
    & > .#{$prefix}-item-container > .#{$prefix}-item-tail::after {
      background-color: $primaryColor;
    }
    & > .#{$prefix}-item-container > .#{$prefix}-item-content {
      & > .#{$prefix}-item-title,
      .#{$prefix}-item-subtitle {
        color: $textColor;
      }
    }
  }
}

.#{$prefix} .#{$prefix}-item:not(.#{$prefix}-item-active) > .#{$prefix}-item-container[role='button'] {
  &:hover {
    .#{$prefix}-item-title {
      color: $primaryColor;
    }
  }
} 

.session-steps {
  width: 100%;
  border: 1px solid $borderColor;
  padding: 5px 16px;
  border-radius: 50px;
  box-shadow: 1px 1px 4px rgba(43, 43, 43, 0.15);
  position: relative;
  margin-right: 30px;

  /*@media (min-width: 1600px) {
    left: 30%;
    transform: translateX(-50%);
  }*/

  @media (max-width: 600px) {
    display: none;
  }

  .#{$prefix}-item {

    width: fit-content;
    // padding-left: 8px !important;
    padding: 0 8px 0 2px !important;
    overflow: unset;
    display: flex;
    align-items: center;

    &-content, &-container {
      width: fit-content;
    }

    &-container {
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;
    }

    &-title {
      font-size: 11px;
      color: #474747 !important;
      // padding-right: 12px;
      padding: 3px 8px 0 8px;
      // display: none;
      line-height: 12px;
      text-transform: uppercase;

      &::after {
        display: none;
      }

      @media (max-width: 1200px) {
        display: none;
      }
    }

    &-icon {
      border-radius: 50%;
      height: 32px;
      min-height: 32px;
      max-height: 32px;
      width: 32px;
      min-width: 32px;
      max-width: 32px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      // margin-right: 4px;
      margin-right: 0;

      .#{$prefix}-icon {
        color: #fff;
        height: 24px;
        width: 24px;
        top: inherit;
        left: inherit;
        line-height: 26px;
      }
    }

    &-tail {
      padding: 0 2px !important;
      color: $borderColor;
    }
  }

  .#{$prefix}-item:not(:last-child) {
    &::after {
      content: url('../../images/icons/arrow.svg');
    }
  }

  .#{$prefix}-item-error .#{$prefix}-item-icon {
    background: #ccc !important;
  }

  .#{$prefix}-item-finish .#{$prefix}-item-icon {
    background: $primaryColor !important;
  }

  .#{$prefix}-item-wait .#{$prefix}-item-icon {
    background: #ccc !important;
  }
}
