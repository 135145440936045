@import 'variables';

$prefix: $antPrefix + -descriptions;

.#{$prefix} {
  &-bordered {
    .#{$prefix} {
      &-view,
      &-row,
      &-item-label,
      &-item-content {
        border-color: $contrastBackground;
      }
      &-item-label {
        background: $contrastBackground;
      }
    }
  }
  &-title,
  &-item-label,
  &-item-content {
    color: $textColor;
  }
}

.#{$prefix}-item {
  padding: 0 8px 20px 0 !important;
}

.#{$prefix}-item-container {
  flex-direction: column;

  .#{$prefix}-item-label {
    opacity: 0.6;
    font-size: 15px;
  }

  .#{$prefix}-item-content > article {
    font-size: 16px;
  }
}

@media (max-width: 768px) {
  .#{$prefix}-row {
    display: flex;
    flex-flow: column;
  }
}