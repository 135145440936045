@import 'variables';

$prefix: $antPrefix + -list;

.#{$prefix} {
  color: $textColor;
  &-item-meta {
    &-title,
    &-description {
      color: $textColor;
    }
  }
  &-item {
    border-color: $borderColor !important;

    .anticon-right {
      margin-right: 6px;
    }
  }
}

.#{$prefix}-split .#{$prefix}-item {
  border-bottom: none;

  @media (max-width: 1250px) {
    flex-flow: row wrap;
  }
}

.#{$antPrefix}-card-body .#{$prefix}-split .#{$prefix}-item:not(:last-child), .program-files-list .#{$prefix}-item:not(:last-child) {
  border-bottom: 1px solid #f0f0f0;
}

.#{$prefix}-item-meta {
  align-items: center;
}

.#{$prefix}-item-meta-title {
  margin-bottom: 0;
  white-space: pre-wrap;
  min-width: 140px;
}

.#{$antPrefix}-card-body > .#{$prefix}-split .#{$prefix}-item, .#{$antPrefix}-card-body {
  .#{$antPrefix}-space-item {
    margin-right: 4px !important;
  }
  .#{$antPrefix}-space-item > .#{$antPrefix}-divider-vertical {
    margin: 0 4px;
  }
}