@import 'variables';

$prefix: $antPrefix + -divider;

.#{$prefix} {
  &-horizontal {
    &.#{$prefix} {
      &-with-text-center,
      &-with-text-left,
      &-with-text-right {
        color: $textColor;
        &::before,
        &::after {
          border-color: $textColor;
        }
      }
    }
  }
}

.#{$prefix}-with-text-left, .#{$prefix}-horizontal.#{$prefix}-with-text {
  span {
    padding: 0 8px;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 4px;
  }

  &::before {
    width: 20px;
  }

  &::after {
    border-color: $textColor;
  }
}