@import 'variables';

$prefix: $antPrefix + -collapse;

.#{$prefix} {
  color: $textColor !important;
  background: $componentBackground;
  line-height: 35px;
  border-radius: 4px;
  &-borderless {
    background-color: $componentBackground !important;
    & > .#{$prefix}-item > .#{$prefix}-content {
      background: transparent !important;
    }
  }

  & > .#{$prefix}-item > .#{$prefix}-header {
    color: $textColor !important;
  }

  &-content {
    color: $textColor !important;
    background: $componentBackground;
  }
}

.#{$prefix}-content-box .#{$prefix}-content-box {
  padding: 0 !important;
}

.#{$prefix} > .#{$prefix}-item > .#{$prefix}-header {
  display: flex;
  align-items: center;
  min-height: 42px;
  padding: 8px 0 8px 16px;

  .#{$prefix}-arrow {
    position: inherit;
    top: unset;
    left: unset;
    margin-right: 16px;
  }

  .#{$antPrefix}-statistic-content {
    display: inline-flex;
    align-items: center;
    margin-right: 12px;

    .#{$antPrefix}-statistic-content-prefix {
      margin-right: 10px;
    }
  }
}

.#{$prefix} > .#{$prefix}-item:last-child, .#{$prefix} > .#{$prefix}-item:last-child > .#{$prefix}-header,
.#{$prefix} > .#{$prefix}-item:last-child, .#{$prefix} > .#{$prefix}-item:last-child > .#{$prefix}-header {
  border-radius: 0 0 4px 4px;
}

.session-planning-collapse {
  > .#{$prefix}-item > .#{$prefix}-header {
    padding: 0;
  }
}

.session-tabs .#{$antPrefix}-tabs-content .#{$antPrefix}-card, .session-tabs .#{$antPrefix}-tabs-content .#{$prefix}-content .#{$antPrefix}-table-wrapper {
  margin: 0;
}

.session-contributor-slots-collapse {
  border: 1px solid $borderColor;
  margin: 24px 0 14px 0;

  .#{$antPrefix}-layout-content {
    padding: 0 10px !important;
  }
}

.session-visibility-options-collapse {

  .#{$prefix}-item > .#{$prefix}-content > .#{$prefix}-content-box {
    padding-top: 0;
  }

  .#{$antPrefix}-layout-content, .ant-page-header {
    padding: 0 !important;
  }

  .#{$antPrefix}-checkbox-wrapper {
    margin: 0 4px 0 0;
  }
}

.session-module-rate-collapse {
  .#{$prefix}-item > .#{$prefix}-header {
    .#{$antPrefix}-row {
      width: 80%;

      > .#{$antPrefix}-col {
        max-width: fit-content;
      }

      .#{$antPrefix}-statistic {
        width: fit-content;
        margin-right: 20px;

        &-title {
          margin-bottom: 3px;
        }

        &-content {
          font-weight: 700;
        }
      }
    }
  }
}

.collapse-with-border {
  .#{$prefix}-item {
    border-radius: 4px;
    border: 1px solid $borderColor;
    margin-bottom: 10px;

    .#{$prefix}-item .#{$prefix}-content {
      padding: 12px 16px;
    }
  }
}

.collapse-generate-fields {

  .#{$antPrefix}-form-item {
    label {
      min-width: 210px;
      width: fit-content;
    }
  }
  
}

.program-fields-collapse, .documents-manager-collapse {
  background-color: $componentBackground;

  .#{$prefix}-content {
    background: $componentBackground;

    .#{$antPrefix}-layout-content {
      padding: 8px !important;
    }

    .#{$antPrefix}-form-item-control {
      max-width: 100%;
      width: 100%;

      .#{$antPrefix}-btn-icon-only {
        .anticon-plus {
          margin-top: 1px;
        }
      }
    }

    .#{$antPrefix}-space-item:first-child {
      min-width: 300px;
      width: 80%;
    }

    .#{$antPrefix}-page-header {
      padding: 12px 0;

      .#{$antPrefix}-page-header-heading-title {
        font-size: 16px;
        line-height: 18px;
      }
    }

    .#{$antPrefix}-page-header:not(:first-child) {
      margin-top: 24px;
    }

    .#{$antPrefix}-form-item-label {
      width: fit-content;
      min-width: 170px;
      text-align: left;
      max-width: 370px;
    }
  }
}