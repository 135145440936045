@import 'variables';

$prefix: $antPrefix + -tabs;

.#{$prefix} {
  color: $textColor;
  > .#{$prefix}-nav::before,
  &-bar {
    border-bottom-color: $bodyBackground;
  }
  &-content {
    color: $textColor;
  }

  &-nav .#{$prefix}-tab {
    &-active,
    &:hover {
      color: $primaryColor;
    }
    &-disabled,
    &-disabled:hover {
      color: $disabledColor;
    }
  }

  &-ink-bar {
    background-color: $primaryColor;
  }
}

.#{$prefix}-tab-btn:focus, .#{$prefix}-tab-remove:focus, .#{$prefix}-tab-btn:active {
  color: $primaryColor;
}

.#{$prefix}-tab-remove:active, .#{$prefix}-tab.#{$prefix}-tab-active .#{$prefix}-tab-btn {
  color: $textColor;
}

.#{$prefix}.dashboard-tabs {
  .#{$prefix}-nav {
    margin: 0;

    &::before {
      border: none;
    }

    .#{$prefix}-tab > .#{$prefix}-tab-btn {
      font-weight: 500;
      text-transform: uppercase;
      letter-spacing: 0.02em;
    }

    .#{$prefix}-tab-active {
      border: 1px solid $borderColor;
      border-bottom: none;
      border-radius: 4px 4px 0 0;
      padding: 0 16px;
      position: relative;

      &::after {
        content: '';
        width: 100%;
        height: 2px;
        background: $componentBackground;
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 99;
      }

      .#{$prefix}-tab-btn {
        color: $primaryColor;
        font-weight: 600;
      }
    }

    .#{$prefix}-ink-bar {
      display: none;
    }
  }

  .#{$prefix}-content-holder {
    border-radius: 0 4px 4px 4px;
    margin: -1px 0 0 0;

    > .#{$prefix}-content {
      border-radius: 0 4px 4px 4px;

      > .#{$prefix}-tabpane {
        border: 1px solid $borderColor;
        border-radius: 0 4px 4px 4px;
      }
    }
  }

  .#{$antPrefix}-page-header {
    background: transparent;
    padding: 8px 10px 0 20px;
    margin-bottom: 24px;

    .#{$antPrefix}-page-header-heading-title {
      font-weight: 500;
      font-size: 15px;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        background: $primaryColor;
        height: 2px;
        width: 100%;
        left: 0;
        bottom: 0;
      }
    }
  }
}

.dashboard-table-right-column {
  padding: 16px 0 16px 20px;

  h1 {
    margin-bottom: 24px;
    font-weight: 700;
    font-size: 18px;
  }

  .#{$antPrefix}-divider-with-text-left {
    span {
      padding: 2px 8px 0 0;
      font-size: 16px;
    }

    &::before {
      display: none;
    }

    &::after {
      border-color: $primaryColor;
    }
  }

  .dashboard-total-prices-wrapper {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    padding-right: 8px;

    p:not(:last-child) {
      margin-right: 24px;
    }

    p {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;

      span:first-child {
        font-size: 14px;
      }

      span:last-child {
        font-size: 18px;
        font-weight: 700;
      }
    }
  }
}

.session-tabs > .#{$prefix}-nav {
  margin-left: 20px;

  .#{$prefix}-tab {
    padding: 12px 5px;
  }

  .#{$prefix}-tab-active .#{$prefix}-tab-btn {
    font-weight: 700;
  }
}

.session-tabs .#{$prefix}-content {

  .#{$prefix}-tabpane {
    padding-bottom: 20px;

    > .#{$antPrefix}-divider-horizontal:not(:first-child), .#{$antPrefix}-list-items .#{$antPrefix}-divider-horizontal:not(:first-child) {
      margin-top: 28px;
    }
  }

  .#{$antPrefix}-card, .#{$antPrefix}-table-wrapper {
    margin: 0 20px;
  }

  .#{$antPrefix}-card-head {
    padding: 0 20px;
  }

  .#{$antPrefix}-card-body {
    .#{$antPrefix}-page-header {
      padding: 0 8px 4px 0;
    }
    .#{$antPrefix}-page-header:not(:first-child) {
      padding-top: 20px;
    }
    .#{$antPrefix}-page-header-heading-title {
      font-size: 16px;
    }
  }

  .#{$antPrefix}-collapse {
    border-radius: 4px;

    .#{$antPrefix}-collapse-content {
      border-radius: 0 0 4px 4px;
      background: #fff;
    }
  }

  .anticon-close {
    color: rgba(0, 0, 0, 0.6);

    &:hover {
      color: $secondaryColor;
    }
  }
}

.profile-tabs {
  margin-top: 10px;
  border-top: 1px solid $borderColor;
  padding-top: 16px;
  .#{$prefix}-nav {
    .#{$prefix}-tab-active .#{$prefix}-tab-btn {
      font-weight: 700;
    }
  }  
}

.subscription-tab {
  .#{$antPrefix}-page-header.has-breadcrumb {
    padding: 10px 10px 20px 0;
  }
  > .subscription-wrapper:first-child {
    padding-bottom: 32px;
    border-bottom: 1px solid $borderColor;
    margin-bottom: 12px;
  }
  .subscription-card {
    margin-bottom: 32px;
    border-radius: 6px;
    transition: transform 0.2s linear;
    .#{$antPrefix}-card-head {
      border: none;
      border-radius: 6px 6px 0 0;
      &-wrapper {
        display: flex;
        flex-flow: column;
        align-items: center;
        .#{$antPrefix}-card-head-title, span { color: #fff; }
        .#{$antPrefix}-card-head-title {
          padding: 16px 0 0 0;
          font-size: 24px;
          font-weight: 600;
        }
        .#{$antPrefix}-card-extra {
          padding: 0 0 16px 0;
          float: unset;
          margin: 0;
        }
      }
    }
    .#{$antPrefix}-card-body {
      background: rgba(255, 255, 255, 0.98);
      border-radius: 0 0 6px 6px;
      .#{$antPrefix}-list-item p {
        margin-bottom: 5px;
        color: rgba(0, 0, 0, 0.5);
        width: 100%;
        display: flex;
        justify-content: space-between;
        strong {
          color: rgba(0, 0, 0, 0.9);
        }
      }
    }
    .payment-btn-row {
      position: absolute;
      top: 95px;
      left: 0;
      width: 100%;
      display: flex;
      justify-content: center;
      .#{$antPrefix}-btn {
        height: 38px;
        background: linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0.3) 100%);
        box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.15);
        border-radius: 50px;
        font-size: 15px;
        line-height: 18px;
        text-align: center;
        color: rgba(0, 0, 0, 0.7);
        border: none;
        transition: all 0.4s;
        &:hover {
          background: #fff;
          color: $primaryColor;
        }
      }
    }
  }
  .subscription-card:hover {
    transform: scale(1.06);
  }

  // Code for pulsing booster card animation
  .pulse {
    box-shadow: 0 0 0 rgba(255, 109, 0, 0.8);
    animation: pulse 1.7s infinite;
  }
  .pulse:hover {
    animation: none;
  }
  @-webkit-keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0 rgba(255, 109, 0, 0.8);
    }
    70% {
        -webkit-box-shadow: 0 0 0 28px rgba(204,169,44, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
    }
  }
  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0 rgba(255, 109, 0, 0.8);
      box-shadow: 0 0 0 0 rgba(255, 109, 0, 0.8);
    }
    70% {
        -moz-box-shadow: 0 0 0 28px rgba(204,169,44, 0);
        box-shadow: 0 0 0 14px rgba(204,169,44, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
        box-shadow: 0 0 0 0 rgba(204,169,44, 0);
    }
  }
  // end pulse animation code
}