@import 'variables';

$prefix: $antPrefix + -progress;

.#{$prefix} {
  &-text {
    color: $textColorSecondary;
  }
  &.#{$prefix}-status-success {
    .#{$prefix}-inner:not(.#{$prefix}-circle-gradient) .#{$prefix}-circle-path {
      stroke: $addColor;
    }
  }
  .#{$prefix}-inner:not(.#{$prefix}-circle-gradient) .#{$prefix}-circle-path {
    stroke: $primaryColor;
  }
}

.#{$prefix}-inner {
  background: $contrastBackground;
}

.#{$prefix}-line.quiz-progress {
  max-width: 50%;
}

.session-tabs .#{$prefix} {
  .#{$prefix}-success-bg, .#{$prefix}-bg {
    background-color: $primaryColor;
  }
  .#{$prefix}-text {
    color:$primaryColor;
  }
}