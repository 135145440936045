@import 'variables';

$prefix: $antPrefix + -switch;

.#{$prefix} {
  border-color: $borderColor;
  //background-color: $contrastBackground;
  &-checked {
    background-color: $primaryColor;
  }
}

.kanban-switch {
  height: 32px;
  line-height: 32px;
  min-width: 110px;
  margin-right: 5px;

  .#{$prefix}-handle {
    width: 24px;
    height: 24px;
    top: 3.5px;
    left: 4px;

    &::before {
      border-radius: 50%;
    }
  }

  .#{$prefix}-inner {
    font-size: 16px;
  }
}

.kanban-switch.#{$prefix}-checked .#{$prefix}-handle {
  left: calc(100% - 28px);
}