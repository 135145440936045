@import 'variables';

$prefix: $antPrefix + -form;

.#{$prefix} {
  &-item-label > label {
    color: $textColor;
  }
  &-item-control:not(.has-error) {
    .#{$prefix}-explain {
      color: $disabledColor;
    }
  }
  &-item {
    margin-bottom: 10px;
  }
}

.#{$prefix}-horizontal .#{$prefix}-item .#{$prefix}-item-label + .#{$prefix}-item-control {
  margin-left: 0;
}

.submit-form-item {
  width: 100%;

  .#{$prefix}-item-control {
    width: 100%;
    max-width: 100%;
    margin-left: 0;
  }
}

.session-rates-wrapper {
  .rates-by-customer {
    @media (max-width: 1400px) {
      flex-flow: column;
      .#{$prefix}-item-label {
        text-align: left;
      }
    }
  }
}